import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/_environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Case } from "../_models/case";
import { Cat } from '../_models/cat';
import { ClaimNote } from '../_models/noteDetails';
import { AccountService, AlertService } from '..';
import { ReceivedSummary } from '@app/_models/received-summary';
import { DatePipe } from '@angular/common';
import { Peril } from '@app/_models/peril';
import { Note } from '@app/_models/note';
import { Brand } from '@app/_models/brand';
import { NoteyType } from '@app/_models/noteTypes';
import { Router } from '@angular/router';
import { ClaimContact, vClaimContact } from '@app/_models/claimContact';
import { Employee } from '@app/_models/employee.model';
import { VClaim } from '@app/_models/vClaim';
import { VClaimsAll } from '@app/_models/vClaimsAll';
import { PreliminaryInspectionDropDowns } from '@app/_models/preliminary-inspection-ddl';
import { ClaimRecommendationOption } from '@app/_models/claim-recommendation';
import { PreliminaryInspectionDetails } from '@app/_models/preliminary-inspection-details';
import { pagedVClaim } from '@app/_models/paged-vclaim';
import { Trades } from '@app/_models/trades';
import { TradesDetail } from '@app/_models/trades-detail';
import { promises } from 'dns';


@Injectable({
  providedIn: 'root',
})
export class RestService {
  errorMsg: string = '';
  //Inject the HttpClient module to the constructor params
  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  // Get user details
  getUserDetailsByEmail(email: string): Observable<Employee> {
    return this.http
      .get<Employee>(`${environment.commonAPIUrl}/api/Employees/GetEmployeeByEmail/${email}${this.accountService.subscriptionKey}`)
      .pipe(
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          return throwError(err);
        })
      );
  }

    // Get user details
    getEncryptedQueryParam(paramString: string):Observable<string> {
      return this.http
        .get<string>(`${environment.wgilAPIBaseUrl}EncryptionTestApi/EncryptQueryParameter${this.accountService.subscriptionKey}&plaintextParam=${paramString}`)
        .pipe(
          catchError((err) => {
            this.alertService.clear();
            this.alertService.error(err);
            return throwError(err);
          })
        );
    }

  //GET the list of latest cases from the REST API using HttpClient
  // getLatestCases(): Observable<Case[]> {
  //   return this.http
  //     .get<Object[]>(
  //       `${environment.casesApiUrl}` + this.accountService.subscriptionKey
  //     )
  //     .pipe(
  //       // use the map() operator to return the data property of the response object
  //       // the operator enables to map the response of the Observable stream to the data value
  //       map((data) => data.map((obj) => this.toCase(obj))),
  //       catchError((err) => {
  //         this.alertService.clear();
  //         this.alertService.error(err);
  //         //Handle the errors
  //         return throwError(err); //Rethrow it back to component
  //       })
  //     );
  // }

  //GET the list of latest cases from the REST API using HttpClient
  // getAllCases(): Observable<Case[]> {
  //   return this.http
  //     .get<Object[]>(
  //       `${environment.claimsAllApiUrl}` + this.accountService.subscriptionKey
  //     )
  //     .pipe(
  //       // use the map() operator to return the data property of the response object
  //       // the operator enables to map the response of the Observable stream to the data value
  //       map((data) => data.map((obj) => this.toCase(obj))),
  //       catchError((err) => {
  //         this.alertService.clear();
  //         this.alertService.error(err);
  //         //Handle the errors
  //         return throwError(err); //Rethrow it back to component
  //       })
  //     );
  // }

    //GET the list of latest cases from the REST API using HttpClient
  async getVClaim(consultant: string, adminUser: boolean): Promise<Observable<VClaim[]>> {
    var url = `${environment.wgilAPIBaseUrl}` +'vWGICases/Claims' +this.accountService.subscriptionKey + "&Status=Open";
    if (!adminUser){
      (await this.getEncryptedQueryParam(consultant)).subscribe({
        next: (data: string) => {
            url = url + '&consultant=' + data;
        },
        error: (error) => {
          console.error('Error fetching encrypted consultant:', error);
        }
      });
    }

    var claims = this.http
      .get<Object[]>(url)
      .pipe(
        map((data) => data
                .map((obj) => this.toVClaim(obj))
        ),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
      return claims
  }

  /*getVClaimsAll(consultant: string, adminUser: boolean): Observable<VClaimsAll[]> {
    var url = `${environment.wgilAPIBaseUrl}` +'vClaimsAll/Claims' +this.accountService.subscriptionKey + "&Status=Open";
    if (!adminUser){
      url = url + '&consultant=' + this.getEncryptedQueryParam(consultant);
    }
    var claims = this.http
      .get<Object[]>(url)
      .pipe(
        map((data) => data
                .map((obj) => this.toVClaimsAll(obj))
        ),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
      return claims
  }*/

  //GET the list of latest cases from the REST API using HttpClient
  // getAllOpenCases(consultant: string, adminUser: boolean): Observable<Case[]> {
  //   return this.http
  //     .get<Object[]>(
  //       `${environment.wgilAPIBaseUrl}` +
  //         'WgicasesOpens' +
  //         this.accountService.subscriptionKey
  //     )
  //     .pipe(
  //       // use the map() operator to return the data property of the response object
  //       // the operator enables to map the response of the Observable stream to the data value
  //       map((data) =>
  //         adminUser
  //           ? data.map((obj) => this.toCase(obj))
  //           : data
  //               .map((obj) => this.toCase(obj))
  //               .filter((obj) => obj.consultant == consultant)
  //       ),
  //       catchError((err) => {
  //         this.alertService.clear();
  //         this.alertService.error(err);
  //         //Handle the errors
  //         return throwError(err); //Rethrow it back to component
  //       })
  //     );
  // }

  
  async getPagedVClaims(
    pageNumber: number,
    pageSize: number,
    searchTerm: string = '',
    clientFriendlyName: string = '',
    isHMAUser: Boolean = false,
    isLatest: Boolean = true,
    consultant: string = '',
    adminUser: boolean = false
  ): Promise<pagedVClaim> {

    let uriEncodeFriendlyName =encodeURIComponent(clientFriendlyName.toString());
    var url = `${environment.wgilAPIBaseUrl}` +
          'vClaimsAll/PagedClaimRecords' +
          this.accountService.subscriptionKey +
          '&pageNumber=' +
          `${pageNumber}` +
          '&pageSize=' +
          `${pageSize}` +
          '&searchTerm=' +
          `${searchTerm}` +
          '&isHMAUser=' +
          `${isHMAUser}` +
          `&clientFriendlyName=` +
          `${uriEncodeFriendlyName}` +
          '&isOpen=true';
          if (!adminUser)
            {
              let uriEncodeconsultant = encodeURIComponent(consultant.toString());
              url = url + '&consultant=' + `${uriEncodeconsultant}`
            }

            /*(await this.getEncryptedQueryParam(consultant)).subscribe({
              next: (data: string) => {
                  url = url + '&consultant=' + data;
                  return this.http
                  .get<pagedVClaim>(url)
                  .toPromise();
                //return result;
              },
              error: (error) => {
                console.error('Error fetching encrypted consultant:', error);
                return null;
              }
            });*/
          //}
          /*else{
            return this.http
            .get<pagedVClaim>(url)
            .toPromise();
            //return result;
          }*/

          return this.http
          .get<pagedVClaim>(url)
          .toPromise();
    }


  //GET the list of latest cases from the REST API using HttpClient
  getCATClaims(): Observable<Case[]> {
    return this.http
      .get<Object[]>(
        `${environment.wgilAPIBaseUrl +'Cats'}` + this.accountService.subscriptionKey
      )
      .pipe(
        // use the map() operator to return the data property of the response object
        // the operator enables to map the response of the Observable stream to the data value
        map((data) => data.map((obj) => this.toCase(obj))),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  //GET the list of latest cases from the REST API using HttpClient
  getE2eEoLClaims(): Observable<Case[]> {
    return this.http
      .get<Object[]>(
        `${environment.wgilAPIBaseUrl+'E2eEol'}` +
          this.accountService.subscriptionKey
      )
      .pipe(
        // use the map() operator to return the data property of the response object
        // the operator enables to map the response of the Observable stream to the data value
        map((data) => data.map((obj) => this.toCase(obj))),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  //GET the list of latest cases from the REST API using HttpClient
  getCatsList(): Observable<Cat[]> {
    const params = new HttpParams().set('sort', 'catId').set('order', 'desc');

    return this.http
      .get<Object[]>(
        `${environment.wgilAPIBaseUrl +'CatsLists'}` + this.accountService.subscriptionKey,
        { params: params }
      )
      .pipe(
        // use the map() operator to return the data property of the response object
        // the operator enables to map the response of the Observable stream to the data value
        map((data) => data.map((obj) => this.toCAT(obj)).sort()),
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  //GET case data by ID (refNo)
  getCase(id: number): Observable<any> {
    //}: Observable<Case[]>{
    return this.http
      .get(
        `${environment.wgilAPIBaseUrl +'WgicasesLatests'}` +
          '/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //GET case Contact by ID (refNo)
  getClaimContacts(id: number): Observable<any> {
    //}: Observable<Case[]>{
    return this.http
      .get(
        `${environment.wgilAPIBaseUrl+'ClaimContacts/Contacts'}` +
          '/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }
  
  //GET case Contact by ID (refNo)
  getClaimContactsRole(): Observable<any> {
    //}: Observable<Case[]>{
    return this.http
      .get(
        `${environment.wgilAPIBaseUrl +'ClaimContacts/ContactRole'}` +
          this.accountService.subscriptionKey
      )
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }
  postClaimContact(contacts: ClaimContact, refNo: string): Observable<any>  {
    console.log('from post ');

    console.log(contacts);
    
    return this.http
    .post(environment.wgilAPIBaseUrl + 'ClaimContacts/Contacts/'+ refNo + this.accountService.subscriptionKey, contacts)
    .pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }
        this.alertService.clear();
        this.alertService.error(this.errorMsg);
        return throwError(this.errorMsg);
      })
    );
  }

  putClaimContact(contacts: ClaimContact): Observable<any>  {
    return this.http
    .put(environment.wgilAPIBaseUrl + 'ClaimContacts/Contacts/'+ contacts.contactID + this.accountService.subscriptionKey, contacts)
    .pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }
        this.alertService.clear();
        this.alertService.error(this.errorMsg);
        return throwError(this.errorMsg);
      })
    );
  }
  patchClaimContact(contactID: number, patchDocument: any): Observable<any>  {
    return this.http
    .patch(environment.wgilAPIBaseUrl + 'ClaimContacts/Contacts/'+ contactID + this.accountService.subscriptionKey, patchDocument)
    .pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }
        this.alertService.clear();
        this.alertService.error(this.errorMsg);
        return throwError(this.errorMsg);
      })
    );
  }



  async isValidCaseID(refNo: number): Promise<boolean> {
    let retVal: boolean = false;
    const promise = this.http
      .get(
        `${environment.wgilAPIBaseUrl + 'WgicasesAlls'}` +
          '/IsValidRefNo/' +
          refNo +
          this.accountService.subscriptionKey
      )
      .toPromise();
    promise
      .then((data) => {
        retVal = JSON.stringify(data) == 'true' ? true : false;
        if (retVal) this.router.navigate(['./case-details/' + refNo]);
        else {
          this.router.navigate(['.']);
          this.alertService.error(
            'Invalid RefNo ' +
              refNo +
              '. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk'
          );
        }
      })
      .catch(() => {
        retVal = false;
        this.router.navigate(['.']);
        this.alertService.error(
          'Unspecified error. Please contact Mk3 IT-Helpdesk'
        );
      });
    return retVal;
  }

  //GET claim details by ID (Mk3refNo)
  // async getClaimByMk3Ref(id: number) {
  //   const promise = new Promise<void>((resolve, reject) => {
  //     this.http
  //       .get<Case>(
  //         `${environment.claimsAllApiUrl}` +
  //           '/' +
  //           id +
  //           this.accountService.subscriptionKey,
  //         { headers: new HttpHeaders({ timeout: `${20000}` }) }
  //       )
  //       .subscribe({
  //         next: (res: any) => {
  //           return resolve(res);
  //         },
  //         error: (error: any) => {
  //           reject(error);
  //         },
  //         complete: () => {},
  //       });
  //   });
  //   return promise;
  // }

  async getClaimByClaimNumber(caseNo: string) {
    const promise = this.http
      .get<Case[]>(
        `${environment.wgilAPIBaseUrl+'WgicasesAlls'}` +
          '/GetByClaimNumber/' +
          caseNo +
          this.accountService.subscriptionKey,
        { headers: new HttpHeaders({ timeout: `${20000}` }) }
      )
      .toPromise();
    promise
      .then((data: Case[] | undefined) => {
        if (data![0].refNo)
          this.router.navigate(['./case-details/' + data![0].refNo]);
        else {
          this.router.navigate(['.']);
          this.alertService.error(
            'Invalid Claim Number ' +
              caseNo +
              '. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk'
          );
        }
      })
      .catch(() => {
        this.router.navigate(['.']);
        this.alertService.error(
          'Invalid Claim Number ' +
            caseNo +
            '. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk'
        );
      });
  }

  //GET Claim Notes by ID (refNo)
  getClaimNotes(refNo: number): Observable<ClaimNote[]> {
    return this.http
      .get<Object[]>(
        `${environment.wgilAPIBaseUrl + 'WgilClaimNotesLists'}` +
          '/' +
          refNo +
          this.accountService.subscriptionKey
      )
      .pipe(
        map((data) => data.map((obj) => this.toNoteDetails(obj))),
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //GET Claim Received Summaries For the Date Range
  getClaimReceivedSummary(startDate: Date, endDate: Date): Observable<any> {
    return this.http
      .get<ReceivedSummary[]>(
        `${environment.wgilAPIBaseUrl}` +
          'ClaimReceivedSummeries' +
          this.accountService.subscriptionKey +
          '&fromDT=' +
          this.datePipe.transform(startDate, 'yyyy-MM-dd') +
          '&toDT=' +
          this.datePipe.transform(endDate, 'yyyy-MM-dd')
      )
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //GET Perils Summary For the Date Range
  getPerilsSummary(startDate?: Date, endDate?: Date): Observable<any> {
    return this.http
      .get<ReceivedSummary[]>(
        `${environment.wgilAPIBaseUrl}` +
          'PerilsSummaries' +
          this.accountService.subscriptionKey +
          '&fromDT=' +
          this.datePipe.transform(startDate, 'yyyy-MM-dd') +
          '&toDT=' +
          this.datePipe.transform(endDate, 'yyyy-MM-dd')
      )
      .pipe(
        map((data) => data.map((obj) => this.toPeril(obj))),
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  addNewNote(note: Note) {
    return this.http
      .post(
        `${environment.wgilAPIBaseUrl}` +
          'WgilClaimNotes' +
          this.accountService.subscriptionKey,
        note
      )
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Update the Last Contact Date
  updateLastContactDate(id: number, body: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json-patch+json');
    return this.http
      .patch(
        `${environment.wgilAPIBaseUrl}WgiCasesPartials/${id}` +
          this.accountService.subscriptionKey,
        body,
        { headers: headers }
      )
      .subscribe();
  }

  //Update the First Contact Date
  updateFirstContactDate(id: number, body: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json-patch+json');
    return this.http
      .patch(
        `${environment.wgilAPIBaseUrl}WgiCasesPartials/${id}` +
          this.accountService.subscriptionKey,
        body,
        { headers: headers }
      )
      .subscribe();
  }

  getClaimNoteTypes() {
    return this.http
      .get<NoteyType>(
        `${environment.wgilAPIBaseUrl}` +
          'WgilClaimNoteTypes' +
          this.accountService.subscriptionKey
      )
      .pipe(
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          //Handle the errors
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  getPreliminaryInspectionDropdowns(): Observable<PreliminaryInspectionDropDowns> {
    let url = `${environment.datumConnectApiUrl}` +'PreliminaryInspection/GetPreliminaryInspectionDropdowns' +
    this.accountService.subscriptionKey
    return this.http.get<any>(url);
  }

  savePreliminaryInspectionDetails(preliminaryInspectionDetails:PreliminaryInspectionDetails):Observable<any>{
    let url =`${environment.datumConnectApiUrl}` +'PreliminaryInspection/SavePreliminaryInspectionDetails' + this.accountService.subscriptionKey
    return this.http.post(url,preliminaryInspectionDetails);
  }

  getPreLiminaryInspectionDetails(refNo: number):Observable<PreliminaryInspectionDetails>{
    let url = `${environment.datumConnectApiUrl}` +'PreliminaryInspection/GetPreLiminaryInspectionDetails/'+refNo +
    this.accountService.subscriptionKey
    return this.http.get<any>(url);
  }
  sendPreliminaryInspection(preliminaryInspectionDetails: PreliminaryInspectionDetails):Observable<any>{
    let url = `${environment.datumConnectApiUrl}` +'PreliminaryInspection/SendPreliminaryInspection/' + this.accountService.subscriptionKey
    return this.http.post(url,preliminaryInspectionDetails);
  }
  getPreliminaryInspectionReportById(reportId: number):Observable<any>{
    let url = `${environment.datumConnectApiUrl}` +'PreliminaryInspection/GetPreliminaryInspectionReportById/'+reportId +
    this.accountService.subscriptionKey
    return this.http.get<any>(url);
  }

  //Convert the result to a Case object and return
  private toCase(obj: any): Case {
    return {
      refNo: obj.refNo,
      caseNo: obj.caseNo,
      lastNameS: obj.lastNameS,
      policyNo: obj.policyNo,
      recD: obj.recD,
      status: obj.status,
      consultant: obj.consultant,
      situationStreet: obj.situationStreet,
      situationSuburb: obj.situationSuburb,
      state: obj.state,
      postCode: obj.postCode,
      inspDate: obj.inspDate,
      firstContact: obj.firstContact,
      lastContact: obj.lastContact,
      comments: obj.comments,
      bldCompletion: obj.bldCompletion,
      ctsCompletion: obj.ctsCompletion,
      endToEnd: obj.endToEnd,
      buildingCover: obj.buildingCover,
      contentsCover: obj.contentsCover,
      llPolicy: obj.llPolicy,
      llExtras: obj.llExtras,
      emergencyRepair: obj.emergencyRepair,
      largeLoss: obj.largeLoss,
      holdContact: obj.holdContact,
      reportStatus: obj.reportStatus,
      tempAccom: obj.tempAccom,
      bldEstimate: obj.bldEstimate,
      ctsEstimate: obj.ctsEstimate,
      acceptance: obj.acceptance,
      cat: obj.cat1,
      peril: obj.peril,
      dol: obj.dol,
      sentReport: obj.sentReport,
      xsDateRecD: obj.xsDateRecD,
      ecdEstimatedCompletionDate: obj.ecdEstimatedCompletionDate,
      mk3Fee: obj.mk3Fee,
      bldAmtSettled: obj.bldAmtSettled,
      ctsAmtSettled: obj.ctsAmtSettled,
      reInspDate: obj.reInspDate,
      officeContact: obj.officeContact,
      claimStatus: obj.claimStatus,
      statusModified: obj.statusModified,
      reOpened: obj.reOpened,
      lastInspDate: obj.lastInspDate,
      contactDays: obj.contactDays,
      totalEstimate: obj.totalEstimate,
      emergencyRepairModified: obj.emergencyRepairModified,
      dateFinalised: obj.dateFinalised,
      sowProvided: obj.sowProvided,
      sowReceived: obj.sowReceived,
      buildingWorksCompleted: obj.buildingWorksCompleted,
      contentsWorksCompleted: obj.contentsWorksCompleted,
      assessmentType: obj.assessmentType,
      claimFinalisedDate: obj.claimFinalisedDate,
      originalStatus: obj.originalStatus,
      informedInsdAssessmentOutcome: obj.informedInsdAssessmentOutcome,
      signedContractReceived: obj.signedContractReceived,
      formerCaseNo: obj.formerCaseNo,
      optionalExtrasType: obj.optionalExtrasType,
    };
  }
  private toVClaim(obj: any): VClaim {
    return {
      refNo: obj.refNo,
      caseNo: obj.caseNo,
      recordedDate: obj.recordedDate,
      lastName: obj.lastName,
      situationStreet: obj.situationStreet,
      situationSuburb: obj.situationSuburb,
      state: obj.state,
      consultant: obj.consultant,
      consultantID: obj.consultantID,
      postCode: obj.postCode,
      dateOfLoss: obj.dateOfLoss,
      lastInspDate: obj.lastInspDate,
      lastAssessment: obj.lastAssessment,
      lastContact: obj.lastContact,
      contactDays: obj.contactDays,
      isHoldContact: obj.isHoldContact,
      acceptance: obj.acceptance,
      peril: obj.peril,
      estimatedCompletionDate: obj.estimatedCompletionDate,
      statusClaimTypeID: obj.statusClaimTypeID,
      statusClaimType: obj.statusClaimType,
      originalStatus: obj.originalStatus,
      reOpenedDate: obj.reOpenedDate,
      policyNo: obj.policyNo,
      oldCaseNo: obj.oldCaseNo,
      isVulnerableCustomer: obj.isVulnerableCustomer,
      iCACatastrophe: obj.iCACatastrophe,
      insurer: obj.insurer,
      optionalExtrasType: obj.optionalExtrasType,
      daysOpen: obj.daysOpen,
      wipLink: obj.wipLink,
      holdContact: obj.holdContact,
      emergencyRepairType: obj.emergencyRepairType
    };
  }
  
  private toVClaimsAll(obj: any): VClaimsAll {
    return {
      refNo: obj.refNo,
      acceptance: obj.acceptance,
      assessmentType: obj.assessmentType,
      brandID: obj.brandID,
      brandName: obj.brandName,
      buildingAmountSettled: obj.buildingAmountSettled,
      buildingCompletionDate: obj.buildingCompletionDate,
      buildingCover: obj.buildingCover,
      buildingEstimate: obj.buildingEstimate,
      buildingSumInsured: obj.buildingSumInsured,
      buildingWorksCompleted: obj.buildingWorksCompleted,
      caseNo: obj.caseNo,
      catastrophe: obj.catastrophe,
      clientFriendlyName: obj.clientFriendlyName,
      clientID: obj.clientID,
      comments: obj.comments,
      consultant: obj.consultant,
      consultantID: obj.consultantID,
      contactDays: obj.contactDays,
      contentsAmountSettled: obj.contentsAmountSettled,
      contentsCompletionDate: obj.contentsCompletionDate,
      contentsCover: obj.contentsCover,
      contentsEstimate: obj.contentsEstimate,
      contentsSumInsured: obj.contentsSumInsured,
      contentsWorksCompleted: obj.contentsWorksCompleted,
      dateOfLoss: obj.dateOfLoss,
      dateScopeOfWorkProvided: obj.dateScopeOfWorkProvided,
      dateScopeOfWorkSignedReceived: obj.dateScopeOfWorkSignedReceived,
      daysOpen: obj.daysOpen,
      denialEstimate: obj.denialEstimate,
      emergencyRepairEst: obj.emergencyRepairEst,
      emergencyRepairModified: obj.emergencyRepairModified,
      emergencyRepairType: obj.emergencyRepairType,
      enDataClaimID: obj.enDataClaimID,
      endToEnd: obj.endToEnd,
      estimatedCompletionDate: obj.estimatedCompletionDate,
      excessBuilding: obj.excessBuilding,
      excessContents: obj.excessContents,
      excessDateReceived: obj.excessDateReceived,
      firstContactDate: obj.firstContactDate,
      formerCaseNo: obj.formerCaseNo,
      informedInsdAssessmentOutcome: obj.informedInsdAssessmentOutcome,
      inspectionDate: obj.inspectionDate,
      insuredFullName: obj.insuredFullName,
      insurerApprovalReceivedDate: obj.insurerApprovalReceivedDate,
      isENDataClaim: obj.isENDataClaim,
      isHoldContact: obj.isHoldContact,
      isLargeLoss: obj.isLargeLoss,
      isQuoteRequest: obj.isQuoteRequest,
      isRestorationClaim: obj.isRestorationClaim,
      isVulnerableCustomer: obj.isVulnerableCustomer,
      landLordPolicy: obj.landLordPolicy,
      lastContactDate: obj.lastContactDate,
      lastInspDate: obj.lastInspDate,
      lastName: obj.lastName,
      lorAmountSettled: obj.lorAmountSettled,
      officeContact: obj.officeContact,
      officeContactID: obj.officeContactID,
      optionalExtrasType: obj.optionalExtrasType,
      originalStatus: obj.originalStatus,
      peril: obj.peril,
      policyInception: obj.policyInception,
      policyInitialInception: obj.policyInitialInception,
      policyNumber: obj.policyNumber,
      postCode: obj.postCode,
      previousRefNo: obj.previousRefNo,
      receivedDate: obj.receivedDate,
      reInspectionDate: obj.reInspectionDate,
      reOpenedDate: obj.reOpenedDate,
      reportStatus: obj.reportStatus,
      restorationEstimate: obj.restorationEstimate,
      restorationSettledAmount: obj.restorationSettledAmount,
      sentReportDate: obj.sentReportDate,
      signedContractReceived: obj.signedContractReceived,
      situationStreet: obj.situationStreet,
      situationSuburb: obj.situationSuburb,
      state: obj.state,
      statusClaimTypeID: obj.statusClaimTypeID,
      statusModified: obj.statusModified,
      temporaryAccommodation: obj.temporaryAccommodation,
      tempAccomCost: obj.tempAccomCost,
      totalEstimate: obj.totalEstimate,
      mk3Fee: obj.mk3Fee,
      insurerContactName: obj.insurerContactName,
      insurerContactEmail: obj.insurerContactEmail,
      insurerContactPhone: obj.insurerContactPhone,
      statusClaimType: obj.statusClaimType,
      claimRecommendationID: obj.claimRecommendationID,
      claimRecommendation: obj.claimRecommendation,
      buildingPriceRange: obj.buildingPriceRange
    };
  }
  // private toVClaimsAll(obj: any): VClaimsAll {
  //   return {
  //     refNo: obj.refNo,
  //     caseNo: obj.caseNo,
  //     recordedDate: obj.receivedDate,
  //     lastName: obj.lastName,
  //     situationStreet: obj.situationStreet,
  //     situationSuburb: obj.situationSuburb,
  //     state: obj.state,
  //     consultant: obj.consultant,
  //     consultantID: obj.consultantID,
  //     postCode: obj.postCode,
  //     dateOfLoss: obj.dateOfLoss,
  //     sentReportDate: obj.sentReportDate,
  //     lastInspDate: obj.lastInspDate,
  //     lastAssessment: obj.lastAssessment,
  //     lastContactDate: obj.lastContactDate,
  //     contactDays: obj.contactDays,
  //     isHoldContact: obj.isHoldContact,
  //     acceptance: obj.acceptance,
  //     endToEnd: obj.endToEnd,
  //     peril: obj.peril,
  //     estimatedCompletionDate: obj.estimatedCompletionDate,
  //     statusClaimTypeID: obj.statusClaimTypeID,
  //     // statusClaimType: obj.statusClaimType,
  //     originalStatus: obj.originalStatus,
  //     reOpenedDate: obj.reOpenedDate,
  //     policyNumber: obj.policyNumber,
  //     oldCaseNo: obj.oldCaseNo,
  //     isVulnerableCustomer: obj.isVulnerableCustomer,
  //     iCACatastrophe: obj.iCACatastrophe,
  //     insurer: obj.insurer,
  //     optionalExtrasType: obj.optionalExtrasType,
  //     daysOpen: obj.daysOpen,
  //     wipLink: obj.wipLink,
  //     emergencyRepairType: obj.emergencyRepairType,
  //     holdContact: obj.holdContact,
  //   };
  // }
  //Convert the result to a Case object and return
  private toCAT(obj: any): Cat {
    return {
      catId: obj.catId,
      icaCat: obj.icaCat,
    };
  }

  //Convert the result to a Case object and return
  private toNoteDetails(obj: any): ClaimNote {
    return {
      refNo: obj.refNo,
      date: obj.date,
      addedBy: obj.addedBy,
      initiatedBy: obj.initiatedBy,
      notes: obj.notes,
      private: obj.private,
      timeStamp: obj.timeStamp,
      followUpDate: obj.followUpDate,
      typeDescription: obj.typeDescription,
      subTypeDescription: obj.subTypeDescription,
      reasonDescription: obj.reasonDescription,
      reasonId:obj.reasonId
    };
  }

  //Convert the result to a Peril object and return
  private toPeril(obj: any): Peril {
    return {
      perilID: obj.perilID,
      peril: obj.peril,
      count: obj.count,
    };
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }
  async getClaimByMk3Ref(id: number,isHMAUser: boolean,clientFriendlyName :string):Promise<pagedVClaim> {
    let uriEncodeFriendlyName =encodeURIComponent(clientFriendlyName.toString());
    var url =
      `${environment.wgilAPIBaseUrl}` +
      'vClaimsAll/PagedClaimRecords' +
      this.accountService.subscriptionKey +
      '&RefNo=' +
      id +
      '&PageSize=1&isHMAUser='+isHMAUser+'&clientFriendlyName='+uriEncodeFriendlyName;
    return this.http.get<pagedVClaim>(url).toPromise();
  }
    //Get the trades by the refNo
    getTradesByRefNo(refNo: number) {
      return this.http
        .get<TradesDetail>(
          `${environment.contractorAPIMainUrl}` +
          'TradeServices/GetTradesByRefNo/' +
            refNo +
            this.accountService.subscriptionKey
        ).toPromise();
    }

    getBrandById(id: number) {
      return this.http
        .get<Brand>(
          `${environment.commonAPIUrl}` +
          '/api/Brands/' +
            id +
            this.accountService.subscriptionKey
        ).toPromise();
    }
}
